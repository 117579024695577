.header {
    text-align: center;
    color: white;
    font-size: 11.25vw;
    padding: 2vh;
    padding-top: 10vw;
    margin-bottom: 3vh;
}

.twoButtonDiv {
    display: flex;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    justify-content: space-between;
    align-items: center;
}

.twoButtonDiv .button {
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 3.75vw;
    height: 15vw;
}

.twoButtonDiv .button:last-child {
    margin-right: 0;
}

.oneButtonDiv .button {
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    width: 85vw;
    height: 15vw;
}

.button {
    border-radius: 20px;
    background: transparent;
    color: white;
    border: 2px solid white;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 6.25vw;
    margin-bottom: 3.75vw;
}

.imageDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 6.25vw;
}

.imageDiv img {
    border-radius: 50px;
    width: 50vh;
    height: 25vh;
}

.imageDiv p {
    position: absolute;
    transform: translate(0%, 450%);
    color: #343a90;
    font-size: 3vh;
}

.button2 {
    width: 25vh;
    border-radius: 20px;
    background: transparent;
    color: white;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9vh;
    font-size: 3vh;
    margin-bottom: 2vh;
    margin-left: 1vh;
    margin-right: 1vh;
}

.previousButton {
    color: white;
    display: flex;
    position: absolute;
    font-weight: 600;
    bottom: 7.5vw;
    margin-left: 7.5vw;
    font-size: 5vw;
    background: transparent;
    border: none;
    cursor: pointer;
}

.button-clicked {
    background-color: white;
    color: black;
}

.webcam-container {
    /* 초기 스타일 설정 */
    display: flex;
}

/* webcam-active 클래스가 추가된 경우 이미지와 버튼 숨김 */
.webcam-container.webcam-active {
    display: none;
}
/* 
button {
    all: unset;
}

button:focus {
    outline: revert;
} */
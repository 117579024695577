.functionHeader {
    width: 100vw;
    display: flex;
    height: 10.625vw;
    padding-top: 3.75vw;
    justify-content: space-between;
    align-items: center;
}

.headerText {
    font-weight: 900;
    color: #FFFFFF;
    font-size: 6.875vw;
    /* margin-left: 3.75vw; */
}

.resetButton {
    color: white;
    height: 10vw;
    width: 30vw;
    border-radius: 5vw;
    background-color: transparent;
    border: 2px solid white;
    margin-right: 3.75vw;
    font-size: 5vw;
    font-weight: 900;
}

.body {
    background: white;
    border-radius: 30px;
    margin-top: 3.75vw;
    margin-left: 3.75vw;
    margin-right: 3.75vw;
    /* height: 1500px; */
}
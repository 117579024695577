/* HealthDetailCheckMain.css */

/*
.main-container {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.nav-bar {
  top: 0;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  background-color: #F8F9FA;
}

.nav-bar-title {
  padding-left: 5.625vw; 
  margin: 0;
  font-size: 6.25vw;
  font-weight: 900;
  color: #00AF35;
}

.content-container {
  overflow-y: auto;
  height: calc(100vh - 22vh);

}

.section-title {
  height: 12.5vw;
  width: 100vw; 
  background-color: #EBEBEB; 
  padding-left: 5.625vw; 
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
}

.question-container {
  margin-top: 5.625vw;
  margin-left: 5.625vw;
  margin-right: 5.625vw;
  margin-bottom: 5.625vw; 
}

.question-title {
  font-size: 5vw;
  font-weight: 900; 
}

.checkbox-option {
  margin-top: 3.75vw;
  align-items: center; 
}

.checkbox {
  transform: scale(1.5);
  margin-right: 3.125vw;
  cursor: pointer;
}

.radio {
  transform: scale(1.5);
  margin-right: 3.125vw;
  cursor: pointer;
}

.question-footer {
  height: 0.5vw;
  background-color: #EBEBEB; 
  margin-left: 5.625vw;
  margin-right: 5.625vw;
}

*/

input[type=checkbox] {
  display: none;
}

input[type=checkbox]+label {
  padding-left: 28px;
  background-repeat: no-repeat;
  background-image: url('../assets/checkbox_unsel.png');
  background-position-y: 4px;
  background-size: 20px 20px;
  /* 이미지 크기 조절 */
}

input[type=checkbox]:checked+label {
  background-image: url('../assets/checkbox_sel.png');
  object-fit: contain;
}

@media (min-width: 768px) {
  input[type=checkbox]+label {
    padding-left: 52px;
    background-position-y: 4px;
    background-size: 36px 36px;
  }
}

@media (min-width: 1600px) {
  input[type=checkbox]+label {
    padding-left: 9vw;
    background-position-y: 4px;
    background-size: 6vw 6vw;
  }
}

input[type=radio] {
  display: none;
}

input[type=radio]+label {
  padding-left: 28px;
  background-repeat: no-repeat;
  background-image: url('../assets/radio_unsel.png');
  background-position-y: 4px;
  background-size: 20px 20px;
}

input[type=radio]:checked+label {
  background-image: url('../assets/radio_sel.png');
  object-fit: contain;
}

@media (min-width: 768px) {
  input[type=radio]+label {
    padding-left: 52px;
    background-position-y: 4px;
    background-size: 36px 36px;
  }
}

@media (min-width: 1600px) {
  input[type=radio]+label {
    padding-left: 9vw;
    background-position-y: 4px;
    background-size: 6vw 6vw;
  }
}
.mobile-link-info-container {
    text-align: center;
}

.spacing-3vh {
    height: 3vh;
}

.spacing-4vh {
    height: 4vh;
}

.logo-container img,
.profile-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.centered-text {
    text-align: center;
}
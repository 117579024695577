@charset "utf-8";
@import url("http://fonts.googleapis.com/earlyaccess/nanumgothic.css");
-webkit-overflow-scrolling:touch;
/* html5요소 하위브라우져 적용 */
header, footer, section, article, aside, nav, hgroup, details, menu, figure, figcaption {display:block}
/* 모든 Device를 위한 공통 로드 */
/* *{font-family:Nanum Gothic !important;color:#333;font-size:12px;box-sizing: border-box;}
body{position:relative;}
body,html{line-height:18px;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td,figure,figcaption{margin:0;padding:0;}
table{border-collapse:collapse;border-spacing:0}
fieldset,img{border:0}
address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}
ol,ul{list-style:none}
legend,caption{text-indent:-100%;*text-indent:0;white-space:nowrap;overflow:hidden;font-size:0;line-height:0;text-shadow:none;color:transparent;}
a {color:#177ddb;font-size:11px;font-family: Nanum Gothic;text-decoration: none;}
input,textarea,button,select{outline: none;resize: none;}
button {cursor:pointer;} */


/*common*/
input,select {border-radius:3px;border: 1px solid #ccc;background: #fff;padding:5px;margin: 2px 0;}
.text_blue {color:#5e8cfc;}
.text_gray {color:#ccc}
.text_bold {font-weight:bold;}
.text_red {color:red}
.btn_blue{background:#5e8cfc !important;border:1px solid #4d73d1 !important;color:#fff !important;font-weight:bold;}
.btn_point{background:#29824d !important;border:1px solid #145f32 !important;color:#fff !important;font-weight:bold;}
.btn_orange{background:#ff7a31 !important;border:1px solid #df5c14 !important;color:#fff !important;font-weight:bold;}
.btn_red{background:#c21b1b !important;border:1px solid #900000 !important;color:#fff !important;font-weight:bold;}
.btn_blue_100{width:100%;display:inline;margin-top:10px;color:#fff;background: #5e8cfc;padding:10px;border-radius:5px;border:0;}
.btn_black{background:#666 !important;border:1px solid #333 !important;color:#fff !important;font-weight:bold;padding:5px 10px;border-radius:5px;}
.btn_gray{padding:10px 15px;background:#ccc;border:0;border-radius:8px;color:#fff;font-weight:bold;}
.btn_gray_s{padding:2px 5px !important;font-size:11px;background:#ccc;border:0;border-radius:8px;color:#fff;font-weight:bold;}
.line {border-bottom: 1px solid #ddd;}
.thumbnail{max-width:300px;margin: 5px 0;}
.thumbnail_s{max-width:100px;margin: 5px 0;}
.long {width:100%;padding:6px;border-radius:3px; background: #fff; border: 1px solid #ccc;}
.medium {width:200px;padding:6px;box-sizing: border-box;border-radius:3px; background: #fff; border: 1px solid #ccc;}
.long_textarea {height:300px;width:100%;padding:6px;box-sizing: border-box;border-radius:3px; background: #fff; border: 1px solid #ccc;}
.m_textarea {height:200px;width:100%;padding:6px;box-sizing: border-box;border-radius:3px; background: #fff; border: 1px solid #ccc;}
.number {padding:20px;box-sizing: border-box;text-align: center;margin:0 auto;}
.number a {display: inline-block;width:30px;height:30px;border:1px solid #ddd; background: #fff;color:#999;border-radius:50px;margin:2px;text-align:center;line-height:30px;}
.number button {display: inline-block;width:30px;height:30px;border:1px solid #ddd; background: #fff;color:#999;border-radius:50px;margin:2px;text-align:center;line-height:30px;}
.number a.active {color:#fff;background: #333333;border:0 !important;} 
.number button.active {color:#fff;background: #333333;border:0 !important;} 
.input_list_1st{width:200px;}

.gap30{height: 30px}
.gap50{height: 50px}

input.fileUpload{opacity: 0;position: absolute;width:100px;margin:0px;padding:0;box-sizing: border-box;}
.btn_excel{background:#84c344 !important;border:1px solid #60932c !important;color:#fff !important;font-weight:bold;width:100px;box-sizing: border-box;text-align: center;margin-right: 5px;}

.al_r {text-align: right;}
.al_l {text-align: left;}
.al_c {text-align: center;}


/*top*/
#top_area {background: #fff;z-index:100;top:0;left:0;width:100%;height:80px;box-sizing: border-box;z-index: 10;border-bottom:1px solid #eee;position: fixed;}
#top_area .top_logo{float: left;}
#top_area .topmenu_all{display: inline-block;padding:28px;text-align:center;box-sizing: border-box;padding-left: 20px;}
#top_area .topmenu_all a{padding: 5px 15px;margin-left:5px;color:#999;cursor: pointer;background: #fff;border:1px solid #ddd;margin-bottom: 5px;border-radius: 5px;float: left;box-sizing: border-box;}
#top_area .topmenu_all button{padding: 5px 15px;margin-left:5px;color:#999;cursor: pointer;background: #fff;border:1px solid #ddd;margin-bottom: 5px;border-radius: 5px;float: left;box-sizing: border-box;}
#top_area .topmenu_all a.active {color:#fff;font-weight: bold;background: #29824d;border:1px solid #29824d;}
#top_area .topmenu_all button.active{color:#fff;font-weight: bold;background: #29824d;border:1px solid #29824d;}
#top_area .right_area{float:right;display: inline-block;padding:35px 20px;}
#top_area .right_area a{padding:5px 8px;background: #eee;border:1px solid #aaa;color:#666;border-radius:3px;margin-left:10px;}
#top_area .right_area button{padding:5px 8px;background: #eee;border:1px solid #aaa;color:#666;border-radius:3px;margin-left:10px;}
#top_area:after{content:'';display:block;clear:both;}

.left_img{width:50%;position:fixed;height:100%;top:0;background: url(../img/bgimg_01.png) center top;background-size:cover; margin-right:-5px;}
#login_area{padding-left:50%;}
#login_area .wrap{width:200px;margin:20% auto 0;text-align:center;}
#login_area .wrap p {font-size:14px;font-weight:bold;margin-bottom:5px;}
#login_area .wrap .copyright{font-size:11px;color:#999;font-weight: 100;padding: 15px 5px;}
#login_area .wrap input{margin-bottom:5px;width:100%;box-sizing: border-box;padding:10px 5px;}
#login_area .wrap a{width:100%;background:#29824d;color:#fff;display:inline-block;text-align: center;font-weight:bold;box-sizing: border-box;padding:10px 15px;border-radius:3px;}
#login_area .wrap button{width:100%;background:#29824d;color:#fff;display:inline-block;text-align: center;font-weight:bold;box-sizing: border-box;padding:10px 15px;border-radius:3px;}

/*내용*/
#wrap {margin-top:100px;padding:0 20px;width:100%;box-sizing: border-box;min-width:1200px;}
#wrap .top{padding: 30px 0 20px 0;border-bottom:2px solid #2c3c5d;box-sizing: border-box;position:relative} 
#wrap .top .tit {font-size:16px;font-weight: bold;color:#29824d;}
#wrap .top .r_area {float: right;margin-top: -20px;right:5px;}
#wrap .top .r_area input {padding:6px;background: #fff;}
#wrap .top .r_area a {color:#666;padding: 6px 15px;background: #eee; border:1px solid #aaa;font-weight: bold;margin-right:5px;  border-radius:3px;}
#wrap .top .r_area button {color:#666;padding: 6px 15px;background: #eee; border:1px solid #aaa;font-weight: bold;margin-right:5px;  border-radius:3px;}
#wrap section {margin:10px 0; position:relative}
#wrap section .right_btn {position:absolute;top:0; right:0;}
#wrap section .sub_text{padding:10px 0;font-weight:bold;color:#333;font-size:14px;}
#wrap section .sub_text a {color:#666;padding: 6px 15px;background: #eee; border:1px solid #aaa;font-weight: bold;margin-right:5px;  border-radius:3px;}
#wrap section .sub_text button {color:#666;padding: 6px 15px;background: #eee; border:1px solid #aaa;font-weight: bold;margin-right:5px;  border-radius:3px;}
#wrap section table {width:100%;margin-bottom:10px;}
#wrap section table thead {background: #f1f1f1;border-top:1px solid #ddd;font-weight:bold;}
#wrap section table td{padding:10px;box-sizing: border-box;color:#666;}
#wrap section table tbody td {border-bottom:1px solid #ddd;height: 55px;}
#wrap section table tbody tr:first-child {border-top:1px solid #ddd;}
#wrap section table td a {display: inline-block;color:#666;padding: 5px 10px;background:#fff; border:1px solid #aaa;font-weight: bold;margin-right:5px; border-radius:3px;}
#wrap section table td button {display: inline-block;color:#666;padding: 5px 10px;background:#fff; border:1px solid #aaa;font-weight: bold;margin-right:5px; border-radius:3px;}
#wrap section .link_table tr{cursor:pointer;}
#wrap section .link_table tr:hover{background: #f4f6fa;}
#wrap section .sub_tit{margin-top:50px;padding:5px 10px;background: #aaa;color:#fff;}
#wrap section .btn_area a{color:#666;padding: 6px 15px;background: #eee; border:1px solid #aaa;font-weight: bold;margin-right:5px;  border-radius:3px;}
#wrap section .btn_area button{color:#666;padding: 6px 15px;background: #eee; border:1px solid #aaa;font-weight: bold;margin-right:5px;  border-radius:3px;}


#wrap section .input_page td:first-child{color:#2c3c5d;font-weight: bold;width:200px;}
#wrap section .input_page td img{max-width: 200px;margin:5px 0;}
#wrap section .input_page_w400 td:first-child{color:#2c3c5d;font-weight: bold;width:400px;}
#wrap section .input_page_w400 tr.input_ok{background: #f5f5f5;}
#wrap section .input_page .table_r_area{width:200px;text-align:right;}

#wrap .tab_menu {width:100%;text-align:center;padding-top: 20px;}
#wrap .tab_menu a{padding: 10px 20px; background:#ddd;width:150px;display:inline-block;color:#fff;font-weight: bold;margin-left: 1px;}
#wrap .tab_menu button{padding: 10px 20px; background:#ddd;width:150px;display:inline-block;color:#fff;font-weight: bold;margin-left: 1px;}
#wrap .tab_menu a:first-child{border-radius: 20px 0 0 20px;}
#wrap .tab_menu button:first-child{border-radius: 20px 0 0 20px;}
#wrap .tab_menu a:last-child{border-radius:0 20px 20px 0;}
#wrap .tab_menu button:last-child{border-radius:0 20px 20px 0;}
#wrap .tab_menu .active {background: #333;}

#wrap .writer_info  {width:100%;text-align:center;padding-top: 20px;}
#wrap .writer_info .writer_photo {max-width: 200px;margin:0 auto;}
#wrap .writer_info .writer_photo img{border-radius: 10px;}
#wrap .writer_info .writer_name{font-weight: bold;margin:10px 0;font-size:18px;}
#wrap .writer_info a {color:#666;padding: 6px 15px;background: #eee; border:1px solid #aaa;font-weight: bold;margin-right:5px;  border-radius:3px;display:inline-block;}
#wrap .writer_info button {color:#666;padding: 6px 15px;background: #eee; border:1px solid #aaa;font-weight: bold;margin-right:5px;  border-radius:3px;display:inline-block;}
#wrap .writer_info .pay{font-weight: bold;margin:10px 0;font-size:18px;color:#5e8cfc;}


.footer{width:100%;border-top:1px solid #ddd;margin-top:100px;padding:20px 20px 50px;}

.box_gray {border:1px solid #ddd;border-radius:8px;padding:10px;background: #fafafa;margin:10px 0;}

/* HealthDetailCheckResult.css */

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.result-component-container {
  display: flex;
  /* Flexbox 사용 */
  flex-direction: column;
  /* 자식 요소들을 수직 방향으로 정렬 */
  align-items: center;
  text-align: center;
  margin-top: 7.5vw;
  margin-bottom: 7.5vw;
}

.titlelabel {
  margin-top: 0;
  font-size: 11.26vw;
  font-weight: 900;
  text-align: center;
  /* 'center'는 따옴표 없이 작성해야 합니다. */
  color: #353B90;
}

.subtitlelabel {
  margin-top: 3.75vw;
  font-size: 3.75vw;
  font-weight: 700;
  color: #000000;
}

.bar {
  margin-top: 7.5vw;
  height: 5px;
  width: 80vw;
  background-color: #353B90;
}

.resetButton {
  color: white;
  height: 10vw;
  width: 30vw;
  border-radius: 5vw;
  background-color: transparent;
  border: 2px solid white;
  margin-right: 3.75vw;
  font-size: 5vw;
  font-weight: 900;
}

.food-label {
  margin-top: 3.75vw;
  background-color: #353B90;
  color: white;
  font-size: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  padding-right: 3.125vw;
  padding-left: 3.125vw;
  border-radius: 3.5vw;
}

.food-list {
  margin-top: 2.5vw;
  text-align: center;
  font-size: 5vw;
  color: #000000;
  font-weight: 900;
}

.food-summary-box {
  margin-top: 6.25vw;
  background-color: #000000;
  color: #FFFFFF;
  margin-left: 11.25vw;
  margin-right: 11.25vw;
  border-radius: 3vw;
}

.summary-title {
  margin-top: 3.75vw;
  text-align: center;
  font-size: 5vw;
  font-weight: 900;
}

.summary-content {
  margin-top: 5vw;
  text-align: center;
  font-size: 3.75vw;
  font-weight: 900;
  margin-bottom: 3.75vw;
  margin-left: 8vw;
  margin-right: 8vw;
}

.section-bar {
  height: 2.5vw;
  width: 10vw;
  align-self: center;
  background-color: #9296D0;
}

.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2.5vw;
  margin-bottom: 6.25vw;
}

.product-simple-box {
  height: 40vw;
  width: 40vw;
  background-color: #666666;
}

.product-name {
  margin-top: 1.875vw;
  color: #000000;
  font-size: 5vw;
  font-weight: 900;
}

.product-description {
  margin-top: 0.75vw;
  color: #666666;
  font-size: 3.75vw;
  font-weight: 900;
}

.product-tags {
  margin-top: 1vw;
  color: #353B90;
  font-size: 3.75vw;
  font-weight: 900;
}

.purchase-button {
  margin-top: 6.875vw;
  height: 15vw;
  width: 44vw;
  background-color: #353B90;
  color: #ffffff;
  font-size: 6.875vw;
  border-radius: 22vw;
  font-weight: 900;
  border: none;
  cursor: pointer;
}

.symptomTaglabel {
  margin-top: 6.25vw;
  font-size: 3.75vw;
  font-weight: 900;
  text-align: center;
  align-items: center;
  color: #353B90;
}

.contentslabel {
  margin-top: 4vw;
  font-size: 3.75vw;
  font-weight: 900;
  text-align: 'center';
  align-items: center;
  color: #666666;
}

.simple-box {
  height: 30vw;
  width: 30vw;
  background-color: #666666;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 5vh;
  margin-right: 5vh;
}

.main-content {
  padding-bottom: 22.5vw;
}

.fixed-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 22.5vw;
  background-color: #DFE1F1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.next-view-button {
  height: 15vw;
  width: 90vw;
  font-size: 6.875vw;
  background-color: #353B90;
  color: white;
  border-radius: 7.5vw;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3.125vw;
  padding-left: 3.125vw;
  border: none;
}

.fixed-show-box {
  position: fixed;
  top: 20vw;
  width: 90vw;
  left: 5vw;
  height: 25vw;
  background-color: #000000;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 12.5vw;
}

.show-box-title {
  color: #FFFFFF;
  font-size: 4.375vw;
  font-weight: 900;
}

.show-box-start {
  margin-top: 0.5vw;
  color: #00AF35;
  font-size: 7vw;
  font-weight: 900;
}

.no-scroll {
  overflow: hidden;
}

.blink {
  animation: blink 1s linear;
}

@keyframes blink {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}

.shake {
  animation: shake 1s linear;
}

@keyframes shake {
  0%, 100% {
      transform: translateY(0);
  }
  25% {
      transform: translateY(-10px);
  }
  50% {
      transform: translateY(10px);
  }
  75% {
      transform: translateY(-10px);
  }
}

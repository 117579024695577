@font-face {
    font-family: 'Jalnan';
    src: url('../assets/Jalnan.ttf') format('truetype');
}

/* @supports (-webkit-touch-callout: none) {
    .h-screen { */
        /* height: -webkit-fill-available; */
    /* }
} */

.root {
    width: 100vw;
    height: 100vh;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vw;
    margin-top: 5vw;
    margin-bottom: 12.5vw;
}

.logoImage {
    height: 20vw;
    width: 20vw;
}

.homeHeader {
    font-size: 5vw;
    text-align: center;
    margin-bottom: 6.25vw;
}

.text1 {
    color: black;
}

.text2 {
    color: #343a90;
}

.homeBody {
    font-size: 11.25vw;
    text-align: center;
    margin-bottom: 18.75vw;
}

.text3 {
    color: #00af35;
}

.startButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 17.5vw;
}

.startButton {
    background: linear-gradient(to bottom, #7f84c8, #343a90);
    border-radius: 10vw;
    color: white;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vw;
    width: 62.5vw;
    font-size: 10vw;
    font-weight: 900;
}

.functionButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 16.25vw;
}

.functionButton {
    background: white;
    width: 62.5vw;
    border-radius: 50px;
    color: #343a90;
    border: 2px solid #343a90;
    height: 10vw;
    font-size: 5vw;
    text-align: center;
}

.blinking-button {
    animation: blink 1s linear;
}

@keyframes blink {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}